const authTokenKey = "jwtToken";

export const isAuthenticated = () => {
    let result = false;
    let jwtToken = getToken();
    if (jwtToken !== null) {
        result = true;
    }

    return result;
}

export const setAuthenticated = (token) => {
    try {
        localStorage.setItem(authTokenKey, token);

        const payload = JSON.parse(atob(token.split('.')[1]));
        const expiration = payload.exp * 1000; // `exp` 是以秒为单位的，将其转换为毫秒

        localStorage.setItem("jwtExpiration", expiration);
    } catch (e) {
        console.log(e);
    }
}

export const removeToken = () => {
    localStorage.removeItem(authTokenKey);
    localStorage.removeItem("jwtExpiration");
}

export const isTokenExpired = () => {
    const expiration = localStorage.getItem("jwtExpiration");

    if (!expiration) return true;

    const now = Date.now();
    return now > expiration;
};

export const getToken = () => {
    if (isTokenExpired()) {
        localStorage.removeItem(authTokenKey);
        localStorage.removeItem("jwtExpiration");
        return null;
    }
    return localStorage.getItem(authTokenKey);
};