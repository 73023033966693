import React from 'react';

const PostList = ({ formData }) => {
    let idFromAPI = 1;
    return (
        <div className='account-list-div'>
            <a className='account-link-list' href={`/p?id=${formData.shortPostId}`}>
                <div className=''>
                    <div className=''>
                        <div className='account-profile-list-name'>{formData.title}</div>
                        <div className='post-name-list-subtitle mb-2'>{formData.content} more...</div>
                    </div>
                </div>

                {/* <div className="hearts">
                    <span className="heart red">♥</span>
                    <span className="heart red">♥</span>
                    <span className="heart red">♥</span>
                    <span className="heart black">♥</span>
                    <span className="heart black">♥</span>
                    <span className='heartChar'>(1234)</span>
                </div> */}
            </a>
        </div>
    );
};

export default PostList;