import React, { useState } from "react";
import { postImageApiAsync } from '../api/api';

const ImageUpload = () => {
  const [selectedFile, setSelectedFile] = useState(null); // 保存上傳的文件
  const [base64Image, setBase64Image] = useState(""); // 保存轉換後的 Base64 編碼
  const [uploadStatus, setUploadStatus] = useState(""); // 上傳狀態
  const [fileName, setFileName] = useState(""); // 上傳狀態
  const [formData, setFormData] = useState({
    'FileName': '',
    'FileContentBase64': ''
  }); // 文件名稱

  // 處理文件變更事件
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file); // 保存文件
      convertToBase64(file); // 將文件轉換為 Base64
      setFileName(file.name); // 保存文件名稱
    }
  };

  // 將文件轉換為 Base64
  const convertToBase64 = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file); // 讀取文件為數據 URL（Base64）
    reader.onload = () => {
      setBase64Image(reader.result); // 保存 Base64 結果
    };
    reader.onerror = (error) => {
      console.error("轉換失敗:", error);
    };
  };

  // 提交 Base64 圖片到伺服器
  const handleUpload = async () => {
    if (!base64Image) {
      alert("請選擇一個文件！");
      return;
    }

    formData.FileName = fileName;
    formData.FileContentBase64 = base64Image;
    setFormData(formData);

    await postImageApiAsync(formData)
      .then((response) => {
        setUploadStatus("上傳成功！");
      }).catch((error) => {
        console.error("上傳錯誤:", error);
        setUploadStatus("上傳失敗！");
      });
  };

  return (
    <div style={{ padding: "20px" }}>
      <h2>圖片上傳</h2>
      {/* 文件選擇 */}
      <input type="file" accept="image/*" onChange={handleFileChange} />
      <br />
      <br />
      {/* 預覽 Base64 圖片 */}
      {base64Image && (
        <div>
          <h3>預覽</h3>
          <img
            src={base64Image}
            alt="Uploaded Preview"
            style={{ maxWidth: "300px", height: "auto" }}
          />
        </div>
      )}
      <br />
      {/* 上傳按鈕 */}
      <button
        onClick={handleUpload}
        style={{
          padding: "10px 20px",
          backgroundColor: "#333",
          color: "#fff",
          border: "none",
          borderRadius: "5px",
          cursor: "pointer",
        }}
      >
        上傳圖片
      </button>
      {/* 上傳狀態 */}
      {uploadStatus && <p>{uploadStatus}</p>}
    </div>
  );
};

export default ImageUpload;
