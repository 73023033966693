import React, { useEffect, useState,useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Heart from '../Heart';
import '../../assets/post.css';
import '../../assets/common.css';

const PostIdeaBriefSub = ({formData}) => {
    // store variable value and define a function called setData.
    const [rating, setRating] = useState(0); // 保存當前評分
    const [data, setData] = useState([]);
    const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });
    const [isModalOpen, setIsModalOpen] = useState(false); // 控制 modal 的顯示狀態
    const modalTriggerRef = useRef(null); // 用於獲取按鈕位置

    const toggleModal = () => {
        if (!isModalOpen && modalTriggerRef.current) {
          const rect = modalTriggerRef.current.getBoundingClientRect();
          setModalPosition({
            top: rect.top + window.scrollY - 115, // 按鈕底部位置
            left: rect.left + window.scrollX - 185, // 按鈕左側位置
          });
        }
        setIsModalOpen((prev) => !prev);
      };

    let idFromAPI = 1;
    return (
            <div className="mb-5 content-main">
                <a className='remove-link-css' href='/a?id=1'>
                    <div>
                        {false ? 
                        <img src="https://via.placeholder.com/40" className="post-profile-image"/> 
                        : 
                        <span className='post-profile-image-FirstName'>J</span>}
                        <span className='post-profile-name'>Jim Liu</span>
                    </div>
                </a>
                <a className='remove-link-css' href={`/p?id=${formData.shortPostId}`}>
                    <h6 className="post-item-introduce">{formData.title}</h6>
                    <p className='post-item-content'>{formData.content} more...
                    </p>
                </a>
                {/* <div className="d-flex justify-content-between">
                         <div className="hearts">
                            <span className="heart red fa-solid fa-heart"></span>
                            <span className="heart red fa-solid fa-heart"></span>
                            <span className="heart red fa-solid fa-heart"></span>
                            <span className="heart black fa-solid fa-heart"></span>
                            <span className="heart black fa-solid fa-heart"></span>
                            <span className='heartChar'>(1234)</span>
                       
                        <span className="heartChar">新北 · 2021.06.30</span>
                        <span className="heartChar">＃爭夥伴！</span>
                    </div>
                    <div>
                        <span onClick={toggleModal} ref={modalTriggerRef} className={`shareIcon fa-solid fa-heart ${rating > 0 ? "heart red fa-solid":"black"}`}></span>
                        <span className="fa-solid fa-share shareIcon"></span>
                        { isModalOpen && <Heart rating={rating} setRating={setRating} modalPosition={modalPosition} formData={formData} toggleModal={toggleModal} />}
                    </div>
                </div> */}
            </div>
    );
};

export default PostIdeaBriefSub;