import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Layout from './components/Layout';
import Home from './pages/Home';
import Post from './pages/Post';
import CreatePost from './pages/CreatePost';
import Account from './pages/Account';
import AccountSelf from './pages/AccountSelf';
import Login from './pages/Login';
import ProtectedRoute from './utils/ProtectedRoute';
import AuthProvider from "./store/AuthProvider";
import MarkdownTest from './components/Post/MarkdownTest';
import MarkdownTestInput from './components/Post/MarkdownTestInput';

function App() {
  return (
    <Router>
      <Layout>
        <Routes>
        <Route path="/m" element={<MarkdownTest />} />
        <Route path="/mi" element={
            <ProtectedRoute>
               <MarkdownTestInput />
             </ProtectedRoute>
          } />
          <Route path="/" element={<Post />} />
          <Route path="/p" element={<Post />} />
          <Route path="/cp" element={
            <ProtectedRoute>
              <CreatePost />
             </ProtectedRoute>
          } />
          <Route path="/a" element={<Account />} />
          <Route path="/jlIf1" element={
            <AuthProvider>
              <Login />
            </AuthProvider>
            } />
          <Route path="/as" element={
            <ProtectedRoute>
              <AccountSelf />
           </ProtectedRoute>
            } />
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;
