// src/components/Footer.js
import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-dark">
        <div className="container footer">
            <div className="row">
                <h3 className='text-white'>Jim Liu's Life In Code.</h3>
                <div className="col-12 col-md-6">
                    <p className='text-white'>If you could code, what would you want to do?</p>
                </div>
                <div className="col-12 col-md-6 text-md-end">
                    <p className='text-white'>Jim Liu's Life In Code. Powered by Jim Liu.</p>
                </div>
            </div>
        </div>
    </footer>
  );
};

export default Footer;