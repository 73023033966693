import React from 'react';

const SidePostGroupSub = ({ posts }) => {
    let idFromAPI = 2;
    return (
        <div className='side-post-category'>
            <a className='remove-link-css' href={`/p?ci=${posts.categoryId}`}>
                <div className="">
                    <span>{posts.categoryName} ({posts.postCount})</span>
                </div>
            </a>
        </div>
    );
};

export default SidePostGroupSub;