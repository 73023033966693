import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { postApiAsync } from "../../api/api";
import '../../assets/post.css';
import '../../assets/common.css';
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeHighlight from "rehype-highlight";
import "highlight.js/styles/github.css";
import { Helmet } from "react-helmet";
import remarkHtml from "remark-html";
import remarkParse from "remark-parse";
import { unified } from "unified";

const PostIdea = () => {
    const [formData, setFormData] = useState([]); // 所有文章數據
    const [html, setHtml] = useState("");

    // 取得查詢字串
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');

    // 初始和分頁加載數據
    useEffect(() => {

        // 定義異步函數以調用 API 並處理 Markdown
        const fetchData = async () => {
            try {
                const response = await postApiAsync(id);
                console.log("API Response:", response);

                setFormData(response); // 更新表單數據

                // 轉換 Markdown 為 HTML
                const processedHtml = await unified()
                    .use(remarkParse) // 解析 Markdown
                    .use(remarkHtml)  // 轉為 HTML
                    .process(response.content);

                setHtml(processedHtml.toString()); // 更新 HTML 狀態
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData(); // 執行異步函數
    }, []);

    const customRenderers = {
        img: ({ src, alt }) => (
            <img
                src={src}
                alt={alt}
                style={{
                    maxWidth: "100%",
                    height: "auto",
                    cursor: "pointer",
                }}
                onClick={() => window.open(src, "_blank")}
            />
        ),
    };

    return (
        <div>
            <Helmet>
                <title>{formData.title}</title>
                <meta name="description" content={html} />
                <meta name="robots" content="index, follow" />
                <meta property="og:title" content={formData.title} />
                <meta property="og:description" content={html} />
            </Helmet>
            <div className="mb-5 content-main">
                {/* <a className='remove-link-css' href='/a?id=1'>
                    <div>
                        <span className='post-profile-image-FirstName'>J</span>
                        <span className='post-profile-name'>Jim Liu</span>
                    </div>
                </a> */}

                <h1 className='post-ideaheader'>{formData.title}</h1>
                {/* <p className='post-item-content'>{formData.content}</p> */}

                <ReactMarkdown
                    components={customRenderers}
                    children={formData.content}
                    remarkPlugins={[remarkGfm]}
                    rehypePlugins={[rehypeHighlight]}
                />

                <div className="d-flex justify-content-between">
                    {/* <div className="hearts">
                        <span className="heart red fa-regular fa-heart"></span>
                        <span className='heartChar'>8/10</span>

                        <span className="heart black fa-regular fa-eye"></span>
                        <span className='heartChar'>815</span>
                    </div> */}
                    {/* <div className="hearts">
                        <span className="heart red">♥</span>
                        <span className="heart red">♥</span>
                        <span className="heart red">♥</span>
                        <span className="heart black">♥</span>
                        <span className="heart black">♥</span>
                        <span className='heartChar'>(1234)</span>
                        <span className="heartChar">新北 · 2021.06.30</span>
                        <span className="heartChar">＃爭夥伴！</span>
                    </div> */}
                    {/* <div>
                        <span className="shareIcon fa-regular fa-heart"></span>
                        <span className="fa-solid fa-share shareIcon"></span>
                    </div> */}
                </div>
            </div>
        </div>
    );
};

export default PostIdea;