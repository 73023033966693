import React,{useContext} from 'react';
import '../../assets/Modal.css';
import '../../assets/header.css';
import { AuthContext } from "../../store/AuthProvider";

const HeaderSettingModal = ({ closeModal }) => {
    const { isAuthenticatedProvider, logout } = useContext(AuthContext);

    return (
        <div className="modal" onClick={closeModal}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                <div className="close" onClick={closeModal}>
                    &times;
                </div>

                <div className='header-profile-title mt-4'>功能</div>
                <ul className='modal-menu'>
                    <a className="remove-link-css" href='/a?id=1'>
                        <li>
                            <i className="icon-lightbulb fa-regular fa-user"></i>
                            <span>About Me</span>
                        </li>
                    </a>
                </ul>
            </div>
        </div>
    );
};

export default HeaderSettingModal;
