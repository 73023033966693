import React, { useState, useEffect, useRef, useContext } from 'react';
import HeaderSettingModal from './HeaderSettingModal';
import { useLocation } from 'react-router-dom';
import '../../assets/common.css';
import HeaderProfile from './HeaderProfile';
import { AuthContext } from "../../store/AuthProvider";
import { isAuthenticated } from "../../utils/Auth";

const Header = () => {
    const { isAuthenticatedProvider } = useContext(AuthContext);
    const [isNotSimpleHeader, setIsNotSimpleHeader] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isClickProfile, setIsClickProfile] = useState(false);
    const modalRef = useRef(null);
    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);
    const location = useLocation();

    // vs if 不能處理副作用
    // 副作用（side effects） 指的是任何影響組件以外系統的行為，或者需要處理非純函數的行為。
    // 網路請求、操作 DOM、訂閱事件、計時器等。
    useEffect(() => {
        // 動態更新狀態
        if (location.pathname !== '/') {
            setIsNotSimpleHeader(true);
        } else {
            setIsNotSimpleHeader(false);
        }
    }, [location.pathname]); // 每次路徑改變時觸發

    const clickProfile = () => {
        setIsClickProfile((prev) => !prev);
    };


    useEffect(() => {
        const handleClickOutside = (event) => {
            // 如果點擊不在 modal 或按鈕內，關閉 modal
            if (
                modalRef.current &&
                !modalRef.current.contains(event.target)
            ) {
                setIsClickProfile(false); // 關閉 modal
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <header>
            <nav className="header navbar navbar-expand-lg justify-content-between">
                <div className="container">
                    <a className="navbar-brand" href='/'>
                         <img className='logo-img' src={require('../../assets/images/logo.png')} alt="logo" />
                    </a>

                    <div className='parent-nav-icon'>
                        <a className="btn loginbtn my-2 my-sm-0" href='/a?id=1'>
                            <li className='icon-login fa-regular fa-user'></li>
                            <span>About Me</span>
                        </a>
                    </div>

                    <div className='parent-nav-icon-rwd' onClick={openModal}>
                        <span className="fa-solid fa-gear"></span>
                    </div>
                </div>
            </nav>

            {/* 把function傳進去 */}
            {isModalOpen && <HeaderSettingModal closeModal={closeModal} />}
        </header>
    );
};

export default Header;