import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { postListApiAsync } from "../../api/api";
import "../../assets/post.css";
import "../../assets/common.css";
import PostIdeaBriefSub from "./PostIdeaBriefSub";

const PostIdeaBrief = () => {
  const [formData, setFormData] = useState([]); // 所有文章數據
  const [lastPostId, setLastPostId] = useState(null); // 當前最後一篇文章的 ID
  const [isLoading, setIsLoading] = useState(false); // 是否正在加載
  const [hasMore, setHasMore] = useState(true); // 是否還有更多數據

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const ci = queryParams.get('ci');

  // 初始和分頁加載數據
  useEffect(() => {
    setHasMore(false);
    postListApiAsync('',ci).then((response) => {
        if(response.length > 0) {
            setHasMore(true);
            setFormData(response);
            setLastPostId(response[response.length - 1].shortPostId);

            if(response.length < 3){
              setHasMore(false);
            }
        }
    });
  }, []);

  // 加載數據函數
  const fetchData = async (shortPostId) => {
    if (isLoading || !hasMore) return; // 避免重複請求或無更多數據
    setIsLoading(true);
    try {
      const response = await postListApiAsync(shortPostId); // 傳遞短文章 ID
      if (response.length === 0) {
        // 沒有更多數據
        setHasMore(false);
      } else {
        setFormData((prevData) => [...prevData, ...response]); // 合併新數據
        setLastPostId(response[response.length - 1].shortPostId); // 更新最後一篇文章的 ID
        
        if(response.length < 3){
          setHasMore(false);
        }
      }
    } catch (error) {
      console.error("加載失敗:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // 加載更多文章按鈕點擊處理
  const handleLoadMore = () => {
    fetchData(lastPostId); // 基於最後一篇文章的 ID 加載下一頁數據
  };

  return (
    <div>
      {/* 顯示文章列表 */}
      {formData.map((item) => (
        <PostIdeaBriefSub key={item.shortPostId} formData={item} />
      ))}

      {/* 加載更多按鈕 */}
      {hasMore && (
        <button
          onClick={handleLoadMore}
          disabled={isLoading}
          style={{
            margin: "20px auto",
            display: "block",
            padding: "10px 20px",
            backgroundColor: "#333",
            color: "#fff",
            border: "none",
            borderRadius: "5px",
            cursor: isLoading ? "not-allowed" : "pointer",
            opacity: isLoading ? 0.6 : 1,
          }}
        >
          {isLoading ? "加載中..." : "更多文章"}
        </button>
      )}

      {/* 沒有更多數據提示 */}
      {!hasMore && <p style={{ textAlign: "center", margin: "20px 0" }}>沒有更多文章了</p>}
    </div>
  );
};

export default PostIdeaBrief;
