import axios from 'axios';
import {getToken} from '../utils/Auth';

// const API_BASE_URL = 'https://localhost:7137/api/life';
// const API_BASE_URL = 'http://localhost:5268/api/life';
const API_BASE_URL = 'https://mulproject-cpgydpd6dfdtf0ch.eastasia-01.azurewebsites.net/api/life';

axios.defaults.headers.common["Authorization"] = `Bearer ${getToken()}`;

export const loginApiAsync = async (data) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/jlIf1?n=${data.n}&p=${data.p}`);

    return response.data; // 返回資料
  } catch (error) {
    console.error('Error fetching user data:', error);
    throw error;
  }
};

export const postListApiAsync = async (si,ci) => {
  try {
      const response = await axios.get(`${API_BASE_URL}/post-list/get`,{
        params: {
          si: si,
          ci: ci,
        },
      });

      return response.data; // 返回資料
  } catch (error) {
      console.error('Error fetching user data:', error);
      throw error;
  }
};

export const postApiAsync = async (data) => {
  try {
      const response = await axios.get(`${API_BASE_URL}/post/get`,{
        params: {
          shortPostId: data,
        },
      });

      return response.data; // 返回資料
  } catch (error) {
      console.error('Error fetching user data:', error);
      throw error;
  }
};

export const categoryListApiAsync = async () => {
  try {
      const response = await axios.get(`${API_BASE_URL}/category-list/get`);

      return response.data; // 返回資料
  } catch (error) {
      console.error('Error fetching user data:', error);
      throw error;
  }
};

export const latelyPostListApiAsync = async () => {
  try {
      const response = await axios.get(`${API_BASE_URL}/lately-post-list/get`);

      return response.data; // 返回資料
  } catch (error) {
      console.error('Error fetching user data:', error);
      throw error;
  }
};

export const postImageApiAsync = async (data) => {
  try {
      const response = await axios.post(`${API_BASE_URL}/image/upload-test`,data);

      return response.data; // 返回資料
  } catch (error) {
      console.error('Error fetching user data:', error);
      throw error;
  }
};

export const createPostApiAsync = async (data) => {
  try {
      console.log(data);
      const response = await axios.post(`${API_BASE_URL}/post/create`,data);

      return response.data; // 返回資料
  } catch (error) {
      console.error('Error fetching user data:', error);
      throw error;
  }
};