import React, { useState, useEffect } from 'react';
import SidePostGroupSub from './SidePostGroupSub';
import {categoryListApiAsync} from '../../api/api';

const SidePostGroup = () => {
    const [posts, setPosts] = useState([]);

    // 初始和分頁加載數據
    useEffect(() => {
        categoryListApiAsync().then((response) => {
            if (response.length > 0) {
                setPosts(response);
            }
        });
    }, []);

    return (
        <div className='mb-5 content-main'>
            <div>
                <h4 className='mb-4'>文章分類</h4>
                {
                    posts.map((post) =>
                    (
                        <SidePostGroupSub posts={post} />
                    ))}
            </div>
        </div>
    );
};

export default SidePostGroup;