import React,{useEffect,useState} from 'react';
import SidiPostListSub from './SidePostListSub';
import {latelyPostListApiAsync} from '../../api/api';

const PostList = ({ posts }) => {
    const [formData, setFormData] = useState([]);

    useEffect(() => {
        latelyPostListApiAsync().then((res) => {
            setFormData(res);
        })
    },[])

    return (
        <div className='mb-5 content-main'>
            <div>
                <h4 className='mb-4'>最新文章</h4>
            </div>

            {formData.map((item) => (
                <SidiPostListSub key={item.shortPostId} formData={item} />
            ))}

        </div>
    );
};

export default PostList;