import React, { useState,useContext } from "react";
import { useNavigate } from 'react-router-dom';
import { loginApiAsync } from '../api/api';
import { AuthContext } from "../store/AuthProvider";
import '../assets/common.css';
import '../assets/common-rwd.css';

const Login = () => {
    const { login } = useContext(AuthContext);
    const navigate = useNavigate();
    const [loginData, setLoginData] = useState({
        n: '',
        p: ''
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setLoginData({ ...loginData, [name]: value });
    };

    const Login = async () => {
        let token = await loginApiAsync(loginData);
        login(token);

        navigate('/');
        window.location.reload();
    };

    return (
        <div className="container content-wrapper">
            <div className="mb-5 content-main-Login">
                <div className="d-flex justify-content-between mb-3">
                    <div>
                        <h3>登入</h3>
                    </div>
                </div>

                <div>
                    <div className="mb-3">
                        <label className="form-label">帳號</label>
                        <input name='n' type="text" className="form-control" value={loginData.n || ''} onChange={handleInputChange} />
                    </div>

                    <div className="mb-3">
                        <label className="form-label">密碼</label>
                        <input name='p' type="password" className="form-control" value={loginData.p || ''} onChange={handleInputChange} />
                    </div>
                </div>

                <div className="mb-3 d-flex justify-content-end">
                    <button type="text" className="btn" onClick={Login}>登入</button>
                </div>

            </div>
        </div>
    );
}

export default Login;