import React, { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeHighlight from "rehype-highlight";
// import "highlight.js/styles/github.css";
import "highlight.js/styles/atom-one-dark.css";
import "../../assets/editor.css";
import ImageUpload from "../../utils/ImageUpload";
import { createPostApiAsync,categoryListApiAsync } from "../../api/api";


const MarkdownTestInput = () => {
    const [options, setOptions] = useState([]);
    const [markdown, setMarkdown] = useState('');
    const [formData, setFormData] = useState({
        'Title': '',
        'Content': '',
        'LanguageType': 0,
        'CategoryId': 0,
        'CategoryName': '',
    });

    // 模擬從資料庫加載數據
    useEffect(() => {
        const fetchData = async () => {
            // const dbOptions = await new Promise((resolve) =>
            //     setTimeout(() => resolve([
            //         {'CategoryName': 'Option 1','CategoryId' : 1},{'CategoryName': 'Option 2','CategoryId' : 2} , {'CategoryName': 'Option 3','CategoryId' : 3}
            //     ]), 1000)
            // );

           await categoryListApiAsync().then((response) => {
                console.log(response);
                setOptions(response);
           });
            
        };
        fetchData();
    }, []);

     // 處理選擇變化
  const handleSelectChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

    const handleSubmit = async () => {
        formData.Content = markdown;
        // console.log(formData);
        await createPostApiAsync(formData)
            .then((response) => {
                alert("success");
            });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: name === "LanguageType" || name === "CategoryId" ? Number(value) : value, // 強制將數字字段轉為數字
        });
    };

    const handleMarkdownChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        setMarkdown(e.target.value);
    }

    const customRenderers = {
        img: ({ src, alt }) => (
            <img
                src={src}
                alt={alt}
                style={{
                    maxWidth: "100%",
                    height: "auto",
                    cursor: "pointer",
                }}
                onClick={() => window.open(src, "_blank")}
            />
        ),
    };

    return (
        <div className="container content-wrapper" style={{ marginBottom: "100px" }}>
            <div className="row">
                <div className="col-lg-9 mx-auto">
                    <button onClick={handleSubmit} className="btn btn-primary">Submit</button>
                    <div className="content-main">
                        <div>標題</div>
                        <input name="Title" type="text" style={{ width: "700px", marginBottom: "20px" }} value={formData.Title || ''} onChange={handleChange} />
                        <div>語言</div>
                        <input name="LanguageType" type="number" style={{ width: "700px", marginBottom: "20px" }} value={formData.LanguageType || ''} onChange={handleChange} />                        
                        
                        <div>分類</div>
                        <input name="CategoryName" type="text" style={{ width: "700px", marginBottom: "20px" }} value={formData.CategoryName || ''} onChange={handleChange} />

                        <select id="dynamic-select" name="CategoryId" value={formData.CategoryId} onChange={handleSelectChange}>
                            <option value='' disabled>
                                請選擇
                            </option>
                            {options.map((option) => (
                                <option key={option.categoryId} value={option.categoryId}>
                                    {option.categoryName}
                                </option>
                            ))}
                        </select>

                        <textarea onChange={handleMarkdownChange} name="Content" type="text" rows={8} style={{ width: "700px" }}></textarea>
                        <ReactMarkdown
                            components={customRenderers}

                            remarkPlugins={[remarkGfm]}
                            rehypePlugins={[rehypeHighlight]}
                        >
                            {markdown}</ReactMarkdown>
                    </div>
                </div>
                <div className="col-lg-3">
                    <ImageUpload />
                </div>
            </div>
        </div>
    );
};

export default MarkdownTestInput;